import { ref, watchEffect } from 'vue'
import { defineStore } from 'pinia'
import { computed } from 'vue'
import { format, formatDistanceToNow } from 'date-fns'
import type { Locale } from 'date-fns'
import { useAuthStore } from './auth'
import type User from '@/types/auth/User'
import { useAuthService } from '@/services/AuthService'
import { de, enUS, es, nl, pl, ro, it, cs } from 'date-fns/locale'
import { useNewsStore } from '@/stores/news'

export const useSettingsStore = defineStore('settings', () => {
	const language = ref<string>(navigator.language.split('-')[0] || 'en')
	const locale = ref<string>('en-US') // todo: detect from browser
	const dateFormatOverwrite = ref<string>()
	const skipGuidedTour = ref<boolean>()

	const dateFnsLocale = computed<Locale>(() => {
		switch (language.value) {
		case 'de':
			return de

		case 'nl':
			return nl

		case 'it':
			return it

		case 'cs':
			return cs

		case 'pl':
			return pl

		case 'ro':
			return ro

		case 'es':
			return es

		default:
			return enUS
		}
	})
	// console.log('navigator.language', navigator.languages)

	const setLanguage = (lang: string) => {
		const changed = lang !== language.value
		console.info('language changed from', language.value, 'to', lang)
		// useI18n().locale.value = lang
		language.value = lang

		if (changed && useAuthStore().isLoggedIn()) {
			useNewsStore().fetchNews()
			useNewsStore().fetchAlerts()
		}
	}

	const setUser = async (user: User) => {
		setLanguage(user.language)

		switch (user.country) {
		case 'de':
			locale.value = 'de-DE'
			break

		case 'at':
			locale.value = 'de-AT'
			break

		case 'uk':
			locale.value = 'en-GB'
			break

		case 'nl':
			locale.value = 'nl-NL'
			break

		case 'pl':
			locale.value = 'pl-PL'
			break

		case 'ro':
			locale.value = 'ro-RO'
			break

		case 'it':
			locale.value = 'it-IT'
			break

		case 'fr':
			locale.value = 'fr-FR'
			break

		case 'ae':
			locale.value = 'ar-AE'
			break

		case 'sa':
			locale.value = 'ar-SA'
			break

		case 'cl':
			locale.value = 'es-CL'
			break

		case 'my':
			locale.value = 'ms-MY'
			break

		case 'sg':
			locale.value = 'en-SG'
			break

		default:
			locale.value = 'en-US'
		}

		const settings = await useAuthService().getSettings()

		if (settings.dateFormat) {
			dateFormatOverwrite.value = settings.dateFormat
		}

		if (settings.numberFormatLocale) {
			locale.value = settings.numberFormatLocale
		}

		skipGuidedTour.value = settings.skipGuidedTour || false
	}

	watchEffect(async () => {
		if (skipGuidedTour.value) {
			await useAuthService().updateSettings({ skipGuidedTour: skipGuidedTour.value })
		}
	})

	if (useAuthStore().user && useAuthStore().isLoggedIn()) {
		setUser(useAuthStore().user!)
	}

	const currencyFormatterOfLocale = (locale: string, currency: string, maximumFractionDigits = 2) => Intl.NumberFormat(locale, {
		style: 'currency',
		currency: currency,
		maximumFractionDigits,
	})

	const currencyFormatter = computed(() => (currency: string, maximumFractionDigits = 2) => currencyFormatterOfLocale(locale.value, currency, maximumFractionDigits))

	const numberFormatters = computed(() => ({
		decimals: Intl.NumberFormat(locale.value, {
			style: 'decimal',
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		}),
		integer: Intl.NumberFormat(locale.value, {
			style: 'decimal',
			minimumFractionDigits: 0,
		}),
		dynamic: Intl.NumberFormat(locale.value, {
			style: 'decimal',
			minimumFractionDigits: 0,
			maximumFractionDigits: 4,
		}),
	}))

	const formatFileSize = (bytes: number): string => {
		if (bytes >= 1073741824) {
			return useSettingsStore().numberFormatters.decimals.format(bytes / 1073741824) + ' GB'
		} else if (bytes >= 1048576) {
			return useSettingsStore().numberFormatters.decimals.format(bytes / 1048576) + ' MB'
		} else {
			return useSettingsStore().numberFormatters.decimals.format(bytes / 1024) + ' KB'
		}
	}

	const dateFormatOfLocale = (locale: string): string => {
		switch (locale) {
		case 'de-DE':
		case 'de-AT':
		case 'ro-RO':
		case 'pl-PL':
			return 'dd.MM.yyyy'

		case 'en-GB':
		case 'it-IT':
		case 'fr-FR':
		case 'ar-AE':
		case 'ar-SA':
		case 'es-CL':
			return 'dd/MM/yyyy'

		case 'nl-NL':
			return 'dd-MM-yyyy'

		case 'ms-MY':
		case 'en-SG':
			return 'dd-MM-yy'

		default:
			return 'MM/dd/yyyy'
		}
	}

	const dateFormat = computed(() => {
		if (dateFormatOverwrite.value) {
			return dateFormatOverwrite.value
		}

		return dateFormatOfLocale(locale.value)
	})

	const formatDate = (date: Date|number|string, withTime = false) => {
		if (typeof date === 'string') {
			if (!withTime) {
				const [year, month, day] = date.split('-').map(Number)
				// Create a new Date object in the local timezone
				// Note: month is zero-based in JavaScript
				date = new Date(year, month - 1, day)
			} else {
				date = new Date(date)
			}
		} else if (typeof date === 'number') {
			// unix timestamp
			date *= 1000
		}

		return format(date, dateFormat.value + (withTime ? ' HH:mm' : ''))
	}

	const formatRelativeDate = (date: Date|number|string) => {
		if (typeof date === 'string') {
			date = new Date(date)
		} else if (typeof date === 'number') {
			// unix timestamp
			date *= 1000
		}

		return formatDistanceToNow(date, {
			addSuffix: true,
			locale: dateFnsLocale.value,
		})
	}

	return {
		language,
		locale,
		dateFormat,
		currencyFormatter,
		numberFormatters,
		dateFormatOverwrite,
		skipGuidedTour,
		dateFnsLocale,
		currencyFormatterOfLocale,
		setUser,
		setLanguage,
		formatDate,
		formatRelativeDate,
		dateFormatOfLocale,
		formatFileSize,
	}
})
