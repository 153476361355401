<template>
	<div class="custom-modal-wrapper" ref="modalWrapperElement">
		<transition name="fade">
			<div v-if="modal.show" class="custom-modal" ref="modalElement" :style="{ 'max-width': `${maxWidth}px` }">
				<div v-if="title" class="d-flex justify-content-between mb-2">
					<h4 class="fw-bold text-primary">{{ title }}</h4>
					<button v-if="closeable" class="btn btn-close" @click="$emit('close')"></button>
				</div>
				<slot></slot>
			</div>
		</transition>
	</div>
</template>

<script setup lang="ts">
import { reactive, ref } from 'vue'
import { onMounted } from 'vue'
import { nextTick } from 'vue'
import { onBeforeUnmount } from 'vue'

const modalWrapperElement = ref<HTMLDivElement>()
const modalElement = ref<HTMLDivElement>()

defineProps({
	title: {
		type: String,
		required: false,
	},
	closeable: {
		type: Boolean,
		default: false,
		required: false,
	},
	maxWidth: {
		type: Number,
		default: 450,
		required: false,
	},
})

defineEmits(['close'])

const modal = reactive({
	show: false,
	danger: false,
})

onMounted(() => {
	nextTick(() => {
		modal.show = true
	})
})

onBeforeUnmount(()=> {
	modal.show = false
})
</script>
